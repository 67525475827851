<template>
  <Menu :list="menuList"/>

  <div class="px-5 sm:px-52 mx-auto my-20">
    <Loading :isLoading="this.isLoading" />
    <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
      <div class="row">
        <center>
          <p class="text-xl lg:text-2xl xl:text-3xl mt-5" style="color: #82385b; font-weight: bold;">
            Recuperar contraseña</p>
        </center>
      </div>
    </div>
    <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
      <div class="row">
        <center>
          <p class="md:text-xl lg:text-xl xl:text-2xl" style="color: #6E6E6E;">
            Las instrucciones para recuperar tu contraseña se enviarán al correo que se especifique
          </p>
        </center>
        <center>
          <p v-if="errors.length">
          <ul>
            <li v-for="(error, index) in errors" :key="index">
              <h5 style="color:red">{{ error }}</h5>
            </li>
          </ul>
          </p>
          <br>
        </center>
      </div>
    </div>
    <div class="flex-1 bg-gray-0 px-0 py-1 m-1">
      <div class="flex">
    
        <div class="mx-auto">
          <form @submit="recoverPassword" class="px-3 pt-6 pb-8 mb-4" style="text-align: left;">
            <div class="w-auto md:w-96 mt-0 mb-10">
              <label class="label-input">Ingresa tu correo:</label>
              <input type="email" class="shadow-md border-2 rounded-lg py-3 w-full px-3" id="intputEmailClient" minlength="10" v-model="email"
                required title="Incluye un signo @ en la dirección de correo electrónico."
                pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+([.][a-zA-Z]{2,4}|[.][a-zA-Z]{2,4}[.][a-zA-Z]{2,4})$">
            </div>
            <div style="display:flex;justify-content:center">
              <div>
                <button class="next_button" type="submit" id="">Enviar Correo</button>
              </div>
            </div>
            <div style="display:flex;justify-content:center; margin-top:5px">
              <div>
                <button class="back_button" type="button" id="" v-on:click="redirectLogin()">Cancelar</button>
              </div>
            </div>

            <br>
            <br>
          </form>
        </div>

      </div>
    </div>
  </div>
  <Footer />
</template>

<script>

import("@/assets/css/stylesheet.css");
import Menu from "../components/Menu.vue";
import Footer from "../components/Footer3.vue";
import Loading from "@/components/Loading/VueLoading.vue";
import Swal from "sweetalert2";

import { ref, onBeforeMount } from 'vue';
import menuOptions  from "@/helpers/menu.js";

export default {
  setup() {
    let menuList = ref([]);

    onBeforeMount(async () => {
      await getMenuOptionsList();
    });

    const getMenuOptionsList = async () => {
      await menuOptions.getMenuOptions().then(resp => {
        menuList.value = resp.data.FONCABSA;
      }).catch(err => {
        console.log("🚀 ~ file: Home.vue:223 ~ getMenuOptions ~ err", err);
      });
    };

    return {
      menuList,
      errors: [],
      apiToken: null,
      email: "",
      isLoading: false,
      multiApi: process.env.VUE_APP_MULTIAPI,
      urlWeb: process.env.VUE_APP_URLWEB,
      ccapi: process.env.VUE_APP_CCAPI,
    };
  },
  name: "Login",
  components: {
    Menu,
    Footer,
    Loading,
  },
  mounted() { },
  updated() {
    var input = document.getElementById("intputEmailClient");
    input.oninvalid = function (event) {
      event.target.setCustomValidity(
        "Haz coincidir con el formato solicitado. Ejemplo: ana@ejemplo.com"
      );
      console.log("Entre entre enrtren");
    };
  },
  methods: {
    redirectLogin() {
      // window.location.href = "/login";
      this.$router.push({path:"/login"});
    },
    recoverPassword(e) {
      e.preventDefault();
      this.isLoading = true;

      var peticion = true;
      if (this.email == "") {
        peticion = false;
        this.isLoading = false;
        this.errors.push("El correo no puede estar vacio");
        Swal.fire({
          title: "Aviso",
          text: this.errors,
          icon: "warning",
          confirmButtonColor: "#FEB72B",
        });
      }
      let data = {
        email: this.email.toLowerCase(),
        url: this.urlWeb + "/cambiar-contrasena?referencia=",
        //url_new_password: "http://localhost:8081/cambiar-contrasena?referencia="
      };

      if (peticion == true) {
        this.axios
          .post(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/password/reset`, data)
          .then((response) => {
            this.isLoading = false;
            Swal.fire({
              title: "Aviso",
              text: "Si el correo es válido, recibirás un correo de recuperación.\nEn caso de que no lo encuentre en su bandeja principal, consulte en spam",
              icon: "success",
              confirmButtonColor: "#FEB72B",
            }).then(function () {
              // window.location.href = "/login";
              this.$router.push({path:"/login"});
            });
          })
          .catch((error) => {
            //this.errors = error.response.data.errores
            console.log("Se presentó un error:", error);
            this.isLoading = false;
            Swal.fire({
              title: "Aviso",
              text: "Si el correo es valido, recibirás un correo de recuperación",
              icon: "success",
              confirmButtonColor: "#FEB72B",
            });
          });
      } else {
        this.isLoading = false;
        Swal.fire({
          title: "Aviso",
          text: "Cambio de contraseña invalido",
          icon: "error",
          confirmButtonColor: "#FEB72B",
        });
      }
    },
  },
};
</script>

<style scoped>
.center {
  padding: 00px 0;
  border: 3px solid #ffffff;
  text-align: center;
}

@media (min-width: 766px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

#button_popups {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #feb72b;
  width: 150px;
  height: 30px;
  padding-top: 0%;
}

button {
  outline: none !important;
}
</style>